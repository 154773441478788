@import-normalize;
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;700&family=Overpass:wght@400;600;800&display=swap");

:root {
  color-scheme: light dark;
}

body {
  --color-primary-30-hsl: 16, 11%, 94%;
  --color-primary-40-hsl: 33, 87%, 75%;
  --color-primary-50-hsl: 16, 72%, 54%;
  --color-primary-60-hsl: 16, 50%, 30%;
  --color-primary-70-hsl: 16, 11%, 17%;

  --color-primary-30: hsl(var(--color-primary-30-hsl));
  --color-primary-40: hsl(var(--color-primary-40-hsl));
  --color-primary-50: hsl(var(--color-primary-50-hsl));
  --color-primary-60: hsl(var(--color-primary-60-hsl));
  --color-primary-70: hsl(var(--color-primary-70-hsl));
 
  --color-neutral-10-hsl: 0, 0%, 100%;
  --color-neutral-20-hsl: 0, 0%, 98%;
  --color-neutral-30-hsl: 0, 0%, 75%;
  --color-neutral-40-hsl: 0, 0%, 73%;
  --color-neutral-50-hsl: 0, 0%, 50%;
  --color-neutral-60-hsl: 0, 0%, 22%;
  --color-neutral-70-hsl: 0, 0%, 30%;
  --color-neutral-80-hsl: 0, 10%, 12%;
  --color-neutral-90-hsl: 0, 10%, 8%;

  --color-neutral-10: hsl(var(--color-neutral-10-hsl));
  --color-neutral-20: hsl(var(--color-neutral-20-hsl));
  --color-neutral-30: hsl(var(--color-neutral-30-hsl));
  --color-neutral-40: hsl(var(--color-neutral-40-hsl));
  --color-neutral-50: hsl(var(--color-neutral-50-hsl));
  --color-neutral-60: hsl(var(--color-neutral-60-hsl));
  --color-neutral-70: hsl(var(--color-neutral-70-hsl));
  --color-neutral-80: hsl(var(--color-neutral-80-hsl));
  --color-neutral-90: hsl(var(--color-neutral-90-hsl));

  --text-color: var(--color-neutral-60);
  --background-color: var(--color-neutral-10);
  --background-color-alt: var(--color-neutral-20);
  --border-color: var(--color-neutral-40);

  margin: 0;
  font-family: "Overpass", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text-color);
  background: var(--background-color);
}

@media (prefers-color-scheme: dark) {
  body {
    --text-color: var(--color-neutral-30);
    --background-color: var(--color-neutral-90);
    --background-color-alt: var(--color-neutral-80);
    --border-color: var(--color-neutral-70);
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input,
select,
button {
  font: inherit;
  font-size: inherit;
  color: inherit;
  cursor: pointer;
}

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}
